import React from 'react'
import {withRouter} from 'react-router-dom'
import {applicationError as ErrorPage} from '@pmviulive/vume-reactjs'

    class ErrorBoundaries extends React.Component {
        state = {
            errorFound: false,
            error:null,
            info:null
        }

        componentDidCatch(error, info) {
            this.setState({ 
                errorFound: true ,
                error:error,
                info:info
            })
        }
        homePageHandler = () =>{
               this.setState({ 
                    errorFound: false ,
                    error:null,
                    info:null
                })
                this.props.history.push('/')
        }

        render() {


            if (this.state.errorFound) {
                return <ErrorPage homePage={this.homePageHandler} error={this.state.error} info={this.state.info}/>
            }

            return <React.Fragment>
                {this.props.children}
            </React.Fragment>
        }
}

export default withRouter(ErrorBoundaries)