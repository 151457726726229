import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from './rootSaga'


export default rootReducer => {

    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        rootReducer,
        applyMiddleware(sagaMiddleware)
            );
    sagaMiddleware.run(rootSaga)
  
    return store
  }
  