import * as actionTypes from "../actions/actionTypes";

const initialState = {
  response: null,
  loading: false,
  error: null,
  versionStatus: null
};

const getMonitoringStart = state => {
  const newState = {
    ...state,
    loading: true
  };
  return newState;
};
const getMonitoringSuccess = (state, action) => {
  const newState = {
    ...state,
    error: null,
    response: action.response
  };
  return newState;
};

const getMonitoringFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    error: action.error
  };
  return newState;
};

// ====== APP VERSION

const getAppVersionStatusStart = state => {
  const newState = {
    ...state,
    loading: true,
    versionStatus: null
  };
  return newState;
};
const getAppVersionStatusSuccess = (state, action) => {
  const newState = {
    ...state,
    error: null,
    loading: false,
    versionStatus: action.versionStatus
  };
  return newState;
};

const getAppVersionStatusFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    versionStatus: null,
    error: action.error
  };
  return newState;
};

export const monitoringReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MONITPRING_START:
      return getMonitoringStart(state);
    case actionTypes.GET_MONITPRING_SUCCESS:
      return getMonitoringSuccess(state, action);
    case actionTypes.GET_MONITPRING_FAIL:
      return getMonitoringFail(state, action);

    case actionTypes.GET_APP_VERSION_STATUS_START:
      return getAppVersionStatusStart(state);
    case actionTypes.GET_APP_VERSION_STATUS_SUCCESS:
      return getAppVersionStatusSuccess(state, action);
    case actionTypes.GET_APP_VERSION_STATUS_FAIL:
      return getAppVersionStatusFail(state, action);

    default:
      return state;
  }
};
