import { combineReducers } from "redux";
import configureStore from "./createStore";

import {
  eventsReducer,
  locationsReducer,
  authReducer,
  changePasswordReducer,
  streamsReducer,
  errorHandlerReducer,
  getMetaDataReducer,
  planReducer,
  tagsReducer,
  rolesReducer,
  usersReducer,
  userPlanReducer,
  reportsReducer,
  cacheReducer,
  monitoringReducer,
  commentsReducer,
  qualificationReducer,
} from "@pmviulive/vume-js";
/* ------------- Assemble The Reducers ------------- */

export const reducers = combineReducers({
  events: eventsReducer,
  locations: locationsReducer,
  auth: authReducer,
  changePassword: changePasswordReducer,
  streams: streamsReducer,
  errorHandler: errorHandlerReducer,
  metaData: getMetaDataReducer,
  plans: planReducer,
  tags: tagsReducer,
  roles: rolesReducer,
  users: usersReducer,
  userPlans: userPlanReducer,
  reportedEvents: reportsReducer,
  cache: cacheReducer,
  monitoring: monitoringReducer,
  comments: commentsReducer,
  qualifications: qualificationReducer,
});

export default () => {
  return configureStore(reducers);
};
