import * as actionTypes from "../actions/actionTypes";

const initialState = {
  response: null,
  loading: false,
  error: null
};


const clearCacheStart = state => {
    const newState = {
      ...state,
      response: null,
      loading: true
    };
    return newState;
  };
  const clearCacheSuccess = (state, action) => {
    const newState = {
      ...state,
      loading: false,
      response: action.response,
      error: null
    };
    return newState;
  };
  
  const clearCacheFail = (state, action) => {
    const newState = {
      ...state,
      loading: false,
      response: null,
      error: action.error
    };
    return newState;
  };

export const cacheReducer = (state = initialState, action) => {
   switch (action.type) {
        case actionTypes.CLEAR_CACHE_START:
            return clearCacheStart(state);
          case actionTypes.CLEAR_CACHE_SUCCESS:
            return clearCacheSuccess(state, action);
          case actionTypes.CLEAR_CACHE_FAIL :
            return clearCacheFail(state, action);
      
          default:
            return state;
        }
      };
      