import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { POST, GET, PUT, DELETE } from "../callApiWrapper";

export function* getPlansSaga(action) {
  yield put(actions.getPlansStart());
  let data = {
    pageModel: {
      orders: [
        {
          dir: action.searchModel.orderDir
            ? action.searchModel.orderDir
            : action.searchModel.order,
          property: action.searchModel.orderProperty
            ? action.searchModel.orderProperty
            : "id"
        }
      ],
      pageNumber: action.searchModel.pageNumber,
      pageSize: action.searchModel.pageSize
    },
    searchModel: {
      title: action.searchModel.title ? action.searchModel.title : ""
    }
  };
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield POST(
    `${action.server}api/planing/search`,
    data,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.getPlansSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getPlansFail(response));
  }
}

//   ============================== CALL GET Plans

export function* addPlanSaga(action) {
  yield put(actions.addPlanStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };
  const response = yield POST(
    `${action.server}api/planing`,
    action.data,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.addPlanSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.addPlanFail(response));
  }
}

//   ============================== ADD PLAN

export function* deletePlanSaga(action) {
  yield put(actions.deletePlanStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield DELETE(
    `${action.server}api/planing/${action.id}`,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.deletePlanSuccess(response));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.deletePlanFail(response));
  }
}

//   ============================== DELETE PLAN

export function* getPlanByIdSaga(action) {
  yield put(actions.getPlanByIdStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield GET(
    `${action.server}api/planing/${action.id}`,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.getPlanByIdSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getPlanByIdFail(response));
  }
}

//   ============================== CALL PLAN BY ID

export function* editPlanSaga(action) {
  yield put(actions.editPlanStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };
  const response = yield PUT(
    `${action.server}api/planing`,
    action.data,
    headers
  );

  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.editPlanSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.editPlanFail(response));
  }
}

//   ============================== EDIT PLAN
