import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { POST, GET, PUT, DELETE } from "../callApiWrapper";

export function* clearCacheSaga(action) {
  yield put(actions.clearCacheStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield DELETE(`${action.server}api/cache`, headers);
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.clearCacheSuccess(response));
  } else if ((response && response.status === 403) || response.status === 401) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.clearCacheFail(response));
  }
}
