import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: false,
  response: null,
  error: ""
};

const qualifyEventStart = state => {
  const newState = {
    ...state,
    loading: true,
    response: null
  };
  return newState;
};

const qualifyEventSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const qualifyEventFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.response
  };
  return newState;
};



const qualifyUserStart = state => {
  const newState = {
    ...state,
    loading: true,
    response: null
  };
  return newState;
};

const qualifyUserSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const qualifyUserFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.response
  };
  return newState;
};


const preQualifyUserStart = state => {
  const newState = {
    ...state,
    loading: true,
    response: null
  };
  return newState;
};

const preQualifyUserSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const preQualifyUserFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.response
  };
  return newState;
};



//===================qualifications reducer===================

export const qualificationReducer = (state = initialState, action) => {
  switch (action.type) {
    //get event timeline
    case actionTypes.QUALIFY_EVENT_START:
      return qualifyEventStart(state);
    case actionTypes.QUALIFY_EVENT_SUCCESS:
      return qualifyEventSuccess(state, action);
    case actionTypes.QUALIFY_EVENT_FAIL:
      return qualifyEventFail(state, action);

    case actionTypes.QUALIFY_USER_START:
      return qualifyUserStart(state);
    case actionTypes.QUALIFY_USER_SUCCESS:
      return qualifyUserSuccess(state, action);
    case actionTypes.QUALIFY_USER_FAIL:
      return qualifyUserFail(state, action);

    case actionTypes.PREQUALIFY_USER_START:
      return preQualifyUserStart(state);
    case actionTypes.PREQUALIFY_USER_SUCCESS:
      return preQualifyUserSuccess(state, action);
    case actionTypes.PREQUALIFY_USER_FAIL:
      return preQualifyUserFail(state, action);
    default:
      return state;
  }


}
