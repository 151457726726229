import React, { Component, Suspense, lazy } from "react";
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import Routes from './routes/routes'
import ErrorBoundaries from "./config/errorBoundary";
import { getLocations } from "@pmviulive/vume-js";
import { Spinner } from "@pmviulive/msd-reactjs";

const Routes = lazy(() => import('./routes/routes'));
const Layout = lazy(() => import('./view/layout/layout'));

class App extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.locations && prevProps.locations !== this.props.locations) {
      localStorage.setItem("locations", JSON.stringify(this.props.locations));
    }
  }
  render() {
    return (
      <ErrorBoundaries>
        <Suspense fallback={<Spinner />}>
          <Layout>
              <Routes />
          </Layout>
        </Suspense>
      </ErrorBoundaries>
    );
  }
}
const mapStateToProps = state => {
  return {
    locations: state.locations.locations
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getLocations: server => dispatch(getLocations(server))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
