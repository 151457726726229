import * as actionTypes from '../actions/actionTypes'

const initialState = {
  plans: null,
  plan: null,
  response: null,
  loading: false,
  error: null
}

const getPlansStart = state => {
  const newState = {
    ...state,
    plans: null,
    loading: true
  }
  return newState
}

const getPlansSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    plans: action.plans,
    error: null
  }
  return newState
}
const getPlansFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    plans: null,
    error: action.error
  }
  return newState
}
// ================================================ GET Plans

const deletePlanStart = state => {
  const newState = {
    ...state,
    response: null,
    loading: true
  }
  return newState
}
const deletePlanSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  }
  return newState
}

const deletePlanFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.error
  }
  return newState
}

const editPlanStart = state => {
  const newState = {
    ...state,
    response: null,
    loading: true
  }
  return newState
}
const editPlanSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  }
  return newState
}

const editPlanFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.error
  }
  return newState
}

const addPlanStart = state => {
  const newState = {
    ...state,
    response: null,
    loading: true
  }
  return newState
}
const addPlanSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  }
  return newState
}

const addPlanFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.error
  }
  return newState
}

const getPlanByIdStart = state => {
  const newState = {
    ...state,
    plan: null,
    loading: true
  }
  return newState
}
const getPlanByIdSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    plan: action.Plan,
    error: null
  }
  return newState
}

const getPlanByIdFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    plan: null,
    error: action.error
  }
  return newState
}

export const planReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PLANS_START:
      return getPlansStart(state)
    case actionTypes.GET_PLANS_SUCCESS:
      return getPlansSuccess(state, action)
    case actionTypes.GET_PLANS_FAIL:
      return getPlansFail(state, action)

    case actionTypes.ADD_PLAN_START:
      return addPlanStart(state)
    case actionTypes.ADD_PLAN_SUCCESS:
      return addPlanSuccess(state, action)
    case actionTypes.ADD_PLAN_FAIL:
      return addPlanFail(state, action)

    case actionTypes.GET_PLAN_BY_ID_START:
      return getPlanByIdStart(state)
    case actionTypes.GET_PLAN_BY_ID_SUCCESS:
      return getPlanByIdSuccess(state, action)
    case actionTypes.GET_PLAN_BY_ID_FAIL:
      return getPlanByIdFail(state, action)

    case actionTypes.EDIT_PLAN_START:
      return editPlanStart(state)
    case actionTypes.EDIT_PLAN_SUCCESS:
      return editPlanSuccess(state, action)
    case actionTypes.EDIT_PLAN_FAIL:
      return editPlanFail(state, action)

    case actionTypes.DELETE_PLAN_START:
      return deletePlanStart(state)
    case actionTypes.DELETE_PLAN_SUCCESS:
      return deletePlanSuccess(state, action)
    case actionTypes.DELETE_PLAN_FAIL:
      return deletePlanFail(state, action)

    default:
      return state
  }
}
