import * as actionTypes from "../actions/actionTypes";

const initialState = {
  roles: null,
  role: null,
  response: null,
  loading: false,
  error: null
};

const getSearchRolesStart = state => {
  const newState = {
    ...state,
    roles: null,
    loading: true
  };
  return newState;
};
const getSearchRolesSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    roles: action.roles,
    error: null
  };
  return newState;
};

const getSearchRolesFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    roles: null,
    error: action.error
  };
  return newState;
};

// ================================================ GET SEARCHED ROLES

const getRoleByIdStart = state => {
  const newState = {
    ...state,
    role: null,
    loading: true
  };
  return newState;
};
const getRoleByIdSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    role: action.role,
    error: null
  };
  return newState;
};

const getRoleByIdFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    role: null,
    error: action.error
  };
  return newState;
};

// ======================================================== GET ROLE BY ID

const deleteRoleStart = state => {
  const newState = {
    ...state,
    response: null,
    loading: true
  };
  return newState;
};
const deleteRoleSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const deleteRoleFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.error
  };
  return newState;
};

// ======================================================== GET ROLE BY ID

const addRoleStart = state => {
  const newState = {
    ...state,
    response: null,
    loading: true
  };
  return newState;
};
const addRoleSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const addRoleFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.error
  };
  return newState;
};

// ======================================================== ADD ROLE

const editRoleStart = state => {
  const newState = {
    ...state,
    response: null,
    loading: true
  };
  return newState;
};
const editRoleSuccess = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: action.response,
    error: null
  };
  return newState;
};

const editRoleFail = (state, action) => {
  const newState = {
    ...state,
    loading: false,
    response: null,
    error: action.error
  };
  return newState;
};

// ======================================================== EDIT ROLE

export const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SEARCH_ROLES_START:
      return getSearchRolesStart(state);
    case actionTypes.GET_SEARCH_ROLES_SUCCESS:
      return getSearchRolesSuccess(state, action);
    case actionTypes.GET_SEARCH_ROLES_FAIL:
      return getSearchRolesFail(state, action);

    case actionTypes.GET_ROLE_BY_ID_START:
      return getRoleByIdStart(state);
    case actionTypes.GET_ROLE_BY_ID_SUCCESS:
      return getRoleByIdSuccess(state, action);
    case actionTypes.GET_ROLE_BY_ID_FAIL:
      return getRoleByIdFail(state, action);

    case actionTypes.DELETE_ROLE_START:
      return deleteRoleStart(state);
    case actionTypes.DELETE_ROLE_SUCCESS:
      return deleteRoleSuccess(state, action);
    case actionTypes.DELETE_ROLE_FAIL:
      return deleteRoleFail(state, action);

    case actionTypes.ADD_ROLE_START:
      return addRoleStart(state);
    case actionTypes.ADD_ROLE_SUCCESS:
      return addRoleSuccess(state, action);
    case actionTypes.ADD_ROLE_FAIL:
      return addRoleFail(state, action);

    case actionTypes.EDIT_ROLE_START:
      return editRoleStart(state);
    case actionTypes.EDIT_ROLE_SUCCESS:
      return editRoleSuccess(state, action);
    case actionTypes.EDIT_ROLE_FAIL:
      return editRoleFail(state, action);

    default:
      return state;
  }
};
