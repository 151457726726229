import * as actionTypes from "./actionTypes";
export const getMonitoring = (token, server) => {
  return {
    type: actionTypes.GET_MONITPRING,
    token: token,
    server: server
  };
};

export const getMonitoringStart = () => {
  return {
    type: actionTypes.GET_MONITPRING_START
  };
};

export const getMonitoringSuccess = Response => {
  return {
    type: actionTypes.GET_MONITPRING_SUCCESS,
    response: Response
  };
};

export const getMonitoringFail = error => {
  return {
    type: actionTypes.GET_MONITPRING_FAIL,
    error: error
  };
};

// ======= APP VERSION

export const getAppVersionStatus = (version, server) => {
  return {
    type: actionTypes.GET_APP_VERSION_STATUS,
    version: version,
    server: server
  };
};

export const getAppVersionStatusStart = () => {
  return {
    type: actionTypes.GET_APP_VERSION_STATUS_START
  };
};

export const getAppVersionStatusSuccess = versionStatus => {
  return {
    type: actionTypes.GET_APP_VERSION_STATUS_SUCCESS,
    versionStatus: versionStatus
  };
};

export const getAppVersionStatusFail = error => {
  return {
    type: actionTypes.GET_APP_VERSION_STATUS_FAIL,
    error: error
  };
};
