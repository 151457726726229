import { takeEvery, all, take, cancel } from 'redux-saga/effects'
import * as actionTypes from '../actions/actionTypes'

import * as events from './events'
import * as locations from './locations'
import * as auth from './auth'
import * as changePassword from './changePassword'
import * as metaData from './getMetaData'
import * as tags from './tags'
import * as roles from './roles'
import * as userPlans from './userPlans'
import * as streams from './streams'
import * as reactions from './reactions'
import * as eventView from './eventView'
import * as comments from './comments'
import * as chats from './chats'
import * as socializing from './socializing'
import * as plans from './plans'
import * as users from './users'
import * as reports from './reports'
import * as cache from './cache'
import * as notifications from './notifications'
import * as monitoring from './monitoring'
import * as profile from './profile'
import * as mixer from './mixer'
import * as viubit from './viubit'
import * as buyViubit from './buyViubits'
import * as invoice from './invoice'
import * as profileNotification from './profileNotification'
import * as director from './director'
import * as qualifications from './qualifications'

export function * watchEvents() {
  yield takeEvery(actionTypes.SEARCH_EVENTS, events.searchEventsSaga)
  yield takeEvery(actionTypes.GET_EVENT_BY_ID, events.getEventByIdSaga)
  yield takeEvery(actionTypes.CREATE_EVENT, events.createEventSaga)
  yield takeEvery(actionTypes.CREATE_AND_START_EVENT, events.createAndStartEventSaga)
  yield takeEvery(actionTypes.CREATE_AND_PREVIEW_EVENT, events.createAndPreviewEventSaga)
  yield takeEvery(actionTypes.EDIT_EVENT, events.editEventSaga)
  yield takeEvery(actionTypes.EDIT_EVENT_INFO, events.editEventInfoSaga)
  yield takeEvery(actionTypes.EVENT_CHANGE_STATE, events.eventChangeStateSaga)
  yield takeEvery(actionTypes.JOIN_ON_EVENT, events.joinOnEventSaga)
  yield takeEvery(actionTypes.DELETE_EVENTS, events.deleteEventSaga)
  yield takeEvery(actionTypes.GET_MY_BROADCAST, events.getMyBroadcastSaga)
  yield takeEvery(actionTypes.CHECK_EVENT_BY_ID, events.checkEventByIdSaga)
  yield takeEvery(actionTypes.BIND_EVENT_STREAM, events.bindEventStreamSaga)
  yield takeEvery(actionTypes.GET_ALL_CATEGORIES, events.getAllCategoriesSaga)
  yield takeEvery(actionTypes.TOGGLE_HIDE_EVENT, events.toggleHideEventSaga)
  yield takeEvery(actionTypes.LATEST_EVENTS_CATEGORY, events.latestEventsCategorySaga)
  yield takeEvery(actionTypes.ADD_OWNER_INVITATION, events.addOwnerInvitationSaga)
  yield takeEvery(actionTypes.DELETE_OWNER_INVITATION, events.deleteOwnerInvitationSaga)
  while (true) {
    const allEventRequests = yield all([
      yield takeEvery(actionTypes.GET_EVENTS, events.getEventsSaga),
      yield takeEvery(
        actionTypes.SEARCH_TOP_EVENTS,
        events.searchTopEventsSaga
      ),
      yield takeEvery(actionTypes.USER_LAST_EVENTS, events.userLastEventsSaga)
    ])
    const userEvents = yield takeEvery(
      actionTypes.USER_PROFILE_EVENTS,
      events.userProfileEventsSaga
    )
    const action = yield take([
      actionTypes.GET_USER_EVENTS_CANCEL,
      actionTypes.GET_EVENTS_CANCEL
    ])
    if (action.type === actionTypes.GET_EVENTS_CANCEL) {
      yield cancel([...allEventRequests])
    } else if (action.type === actionTypes.GET_USER_EVENTS_CANCEL) {
      yield cancel(userEvents)
    }
  }
}

export function * watchStreams() {
  yield takeEvery(actionTypes.ADD_STREAMS, streams.addStreamsSaga)
  yield takeEvery(actionTypes.UPDATE_STREAM, streams.updateStreamSaga)
  yield takeEvery(actionTypes.DELETE_STREAM, streams.deleteStreamSaga)
}
export function * watchLocations() {
  yield takeEvery(actionTypes.GET_LOCATIONS, locations.getLocationsSaga)
}
export function * watchAuth() {
  yield all([
    takeEvery(actionTypes.USER_LOGIN, auth.userLoginSaga),
    takeEvery(actionTypes.USER_LOGOUT, auth.userLogoutSaga),
    takeEvery(
      actionTypes.FORGOT_PASSWORD_SEND_CODE,
      auth.forgotPasswordSendCodeSaga
    ),
    takeEvery(
      actionTypes.FORGOT_PASSWORD_CHECK_CODE,
      auth.forgotPasswordCheckCodeSaga
    ),
    takeEvery(
      actionTypes.FORGOT_PASSWORD_CHANGE_PASSWORD,
      auth.forgotPasswordChangePasswordSaga
    ),
    takeEvery(
      actionTypes.USER_REGISTER_SEND_CODE,
      auth.userRegisterSendCodeSaga
    ),
    takeEvery(
      actionTypes.USER_REGISTER_REGISTER,
      auth.userRegisterRegisterSaga
    ),
    takeEvery(actionTypes.USER_SOCIAL_LOGIN, auth.userSocialLoginSaga)
  ])
}
export function * watchUserPlans() {
  yield takeEvery(actionTypes.GET_MY_PLAN, userPlans.getMyPlanSaga)
  yield takeEvery(
    actionTypes.GET_USER_PLAN_BY_ID,
    userPlans.getUserPlanByIdSaga
  )
  yield takeEvery(actionTypes.SET_FREE_PREMIUM, userPlans.setFreePremiumSaga)
  yield takeEvery(actionTypes.ASSIGN_USER_PLANS, userPlans.assignPlanSaga)
}

export function * watchChangePassword() {
  yield all([
    takeEvery(actionTypes.CHANGE_PASSWORD, changePassword.changePasswordSaga)
  ])
}
export function * watchGetMetaData() {
  yield all([takeEvery(actionTypes.GET_META_DATA, metaData.getMetaDataSaga)])
}
export function * watchTags() {
  yield all([takeEvery(actionTypes.GET_RANDOM_TAGS, tags.getRandomTagsSaga)])
}

export function * watchRoles() {
  yield all([
    takeEvery(actionTypes.GET_SEARCH_ROLES, roles.getSearchRolesSaga),
    takeEvery(actionTypes.GET_ROLE_BY_ID, roles.getRoleByIdSaga),
    takeEvery(actionTypes.EDIT_ROLE, roles.editRoleSaga),
    takeEvery(actionTypes.ADD_ROLE, roles.addRoleSaga),
    takeEvery(actionTypes.DELETE_ROLE, roles.deleteRoleSaga)
  ])
}

export function * watchPlans() {
  yield all([
    takeEvery(actionTypes.GET_PLANS, plans.getPlansSaga),
    takeEvery(actionTypes.ADD_PLAN, plans.addPlanSaga),
    takeEvery(actionTypes.DELETE_PLAN, plans.deletePlanSaga),
    takeEvery(actionTypes.GET_PLAN_BY_ID, plans.getPlanByIdSaga),
    takeEvery(actionTypes.EDIT_PLAN, plans.editPlanSaga)
  ])
}

export function * watchUsers() {
  yield all([
    takeEvery(actionTypes.GET_ALL_USER, users.getUsersSaga),
    takeEvery(actionTypes.SEARCH_USERS, users.searchUsersSaga),
    takeEvery(actionTypes.GET_USER_BY_ID, users.getUserByIdSaga),
    takeEvery(actionTypes.ADD_USER, users.addUserSaga),
    takeEvery(actionTypes.EDIT_USER, users.editUserSaga),
    takeEvery(actionTypes.DELETE_USER, users.deleteUserSaga)
  ])
}
export function * watchReactions() {
  yield all([
    takeEvery(actionTypes.ADD_REACTION, reactions.addReactionSaga),
    takeEvery(actionTypes.EDIT_REACTION, reactions.editReactionSaga),
    takeEvery(actionTypes.DELETE_REACTION, reactions.deleteReactionSaga),
    takeEvery(actionTypes.SEARCH_REACTIONS, reactions.searchReactionsSaga)
  ])
}

export function * watchEventView() {
  yield all([
    takeEvery(actionTypes.ADD_TOTAL_VIEW, eventView.addTotalViewSaga),
    takeEvery(actionTypes.ADD_LIVE_VIEW, eventView.addLiveViewSaga),
    takeEvery(actionTypes.DELETE_LIVE_VIEW, eventView.deleteLiveViewSaga)
  ])
}

export function * watchComments() {
  yield all([
    takeEvery(actionTypes.BLOCK_COMMENT, comments.blockCommentSaga),
    takeEvery(actionTypes.ADD_COMMENT, comments.addCommentSaga),
    takeEvery(actionTypes.SEARCH_COMMENTS, comments.searchCommentsSaga),
    takeEvery(actionTypes.DELETE_COMMENT, comments.deleteCommentSaga),
    takeEvery(actionTypes.EDIT_COMMENT, comments.editCommentSaga),

    takeEvery(
      actionTypes.SEARCH_COMMENT_REPLIES,
      comments.searchCommentRepliesSaga
    )
  ])
}

export function * watchChats() {
  yield all([
    takeEvery(actionTypes.ADD_CHAT, chats.addChatSaga),
    takeEvery(actionTypes.SEARCH_CHATS, chats.searchChatsSaga)
  ])
}
export function * watchSocializing() {
  yield all([
    takeEvery(actionTypes.GET_USER_PROFILE, socializing.getUserProfileSaga),
    takeEvery(actionTypes.FOLLOW_USER, socializing.followUserSaga),
    takeEvery(actionTypes.UNFOLLOW_USER, socializing.unfollowUserSaga),
    takeEvery(actionTypes.BLOCK_USER, socializing.blockUserSaga),
    takeEvery(actionTypes.UNBLOCK_USER, socializing.unblockUserSaga),
    takeEvery(actionTypes.GET_BLOCKED_USERS, socializing.getBlockedUsersSaga)
  ])
  while (true) {
    const userFollowers = yield takeEvery(
      actionTypes.GET_FOLLOWERS,
      socializing.getFollowersSaga
    )
    const userFollowing = yield takeEvery(
      actionTypes.GET_FOLLOWINGS,
      socializing.getFollowingsSaga
    )
    const action = yield take([
      actionTypes.GET_FOLLOWINGS_CANCEL,
      actionTypes.GET_FOLLOWERS_CANCEL
    ])
    if (action.type === actionTypes.GET_FOLLOWINGS_CANCEL) {
      yield cancel(userFollowing)
    } else if (action.type === actionTypes.GET_FOLLOWERS_CANCEL) {
      yield cancel(userFollowers)
    }
  }
}

export function * watchReports() {
  yield all([
    takeEvery(actionTypes.REPORT_EVENT, reports.reportEventSaga),
    takeEvery(actionTypes.GET_REPORT_EVENT, reports.getReportEventsSaga),
    takeEvery(actionTypes.BLOCK_EVENTS, reports.blockEventSaga),
    takeEvery(actionTypes.REPORT_COMMENT, reports.reportCommentSaga),
    takeEvery(
      actionTypes.GET_REPORTED_COMMENTS,
      reports.getReportedCommentsSaga
    )
  ])
}

export function * watchNotifications() {
  yield all([
    takeEvery(
      actionTypes.ADD_NOTIFICATION_TOKEN,
      notifications.addNotificationsTokenSaga
    ),
    takeEvery(
      actionTypes.DELETE_NOTIFICATION_TOKEN,
      notifications.deleteNotificationsTokenSaga
    ),
  ])

  while (true) {
    const profilenoti = yield takeEvery(
      actionTypes.GET_MY_NOTIFICATIONS,
    profileNotification.getMyNotificationSaga
    )
    const action = yield take( actionTypes.GET_MY_NOTIFICATIONS_CLEAR)
    if (action.type === actionTypes.GET_MY_NOTIFICATIONS_CLEAR) {
      yield cancel(profilenoti)
    }
  }


}
export function * watchCache() {
  yield all([takeEvery(actionTypes.CLEAR_CACHE, cache.clearCacheSaga)])
}
export function * watchMonitoring() {
  yield all([
    takeEvery(actionTypes.GET_MONITPRING, monitoring.getMonitoringSaga),
    takeEvery(
      actionTypes.GET_APP_VERSION_STATUS,
      monitoring.getAppVersionStatusSaga
    )
  ])
}

export function * watchProfile() {
  yield all([
    takeEvery(actionTypes.GET_MY_PROFILE, profile.getMyProfileSaga),
    takeEvery(actionTypes.EDIT_MY_PROFILE, profile.editMyProfileSaga),
    takeEvery(actionTypes.DELETE_MY_PROFILE, profile.deleteMyProfileSaga)
  ])
}

export function * watchMixer() {
  yield all([
    takeEvery(actionTypes.ADD_DISPLAY_MIX, mixer.addDisplayMixSaga),
    takeEvery(actionTypes.SEARCH_DISPLAY_MIX, mixer.searchDisplayMixSaga),
    takeEvery(actionTypes.DELETE_DISPLAY_MIX, mixer.deleteDisplayMixSaga)
  ])
}

export function * watchViubit() {
  yield all([
    takeEvery(actionTypes.GET_VIUBIT_BALANCE, viubit.getViubitBalanceSaga),
    takeEvery(actionTypes.SEND_GIFT, viubit.sendGiftSaga),
    takeEvery(
      actionTypes.GET_ALL_VIUBIT_PACKAGES,
      viubit.getAllViubitPackagesSaga
    ),
    takeEvery(actionTypes.SEARCH_VIUBIT_HISTORY, viubit.searchViubitHistorySaga)
  ])
}

export function * watchBuyViubit() {
  yield all([
    takeEvery(actionTypes.BUY_VIUBIT_IOS, buyViubit.buyViubitIosSaga),
    takeEvery(actionTypes.BUY_VIUBIT_ANDROID, buyViubit.buyViubitAndroidSaga)
  ])
}

export function * watchInvoice() {
  yield all([
    takeEvery(actionTypes.CREATE_INVOICE, invoice.createInvoiceSaga),
    takeEvery(actionTypes.CHECK_PAID, invoice.checkPaidSaga),
    takeEvery(
      actionTypes.SEARCH_INVOICE_HISTORY,
      invoice.searchInvoiceHistorySaga
    ),
    takeEvery(actionTypes.GET_INVOICE_BY_ID, invoice.getInvoiceByIdSaga)
  ])
}


export function * watchDirector() {
  yield takeEvery(actionTypes.GET_EVENT_TIMELINE, director.getEventTimelineSaga)
  yield takeEvery(actionTypes.CREATE_EVENT_TIMELINE, director.createEventTimelineSaga)
  yield takeEvery(actionTypes.GET_TIMELINE_TEMPLATES_LIST, director.getTimelineTemplatesListSaga)
  yield takeEvery(actionTypes.UPDATE_EVENT_TIMELINE_CURRENT_SCENE, director.updateEventTimelineCurrentSceneSaga)
  yield takeEvery(actionTypes.CREATE_TIMELINE_TEMPLATE, director.createTimelineTemplateSaga)
  yield takeEvery(actionTypes.GET_TIMELINE_TEMPLATE, director.getTimelineTemplateSaga)
  yield takeEvery(actionTypes.DELETE_TIMELINE_TEMPLATE, director.deleteTimelineTemplateSaga)
}




export function * watchQualification() {
  // Event Qualification
  yield takeEvery(actionTypes.QUALIFY_EVENT, qualifications.qualifyEventSaga);
  // User Qualification
  yield takeEvery(actionTypes.QUALIFY_USER, qualifications.qualifyUserSaga);
  // User Pre-Qualification
  yield takeEvery(actionTypes.PREQUALIFY_USER, qualifications.preQualifyUserSaga);
}
