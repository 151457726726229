import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { PUT } from "../callApiWrapper";

export function* qualifyEventSaga(action) {
  yield put(actions.qualifyEventStart());

  // Here, we use the 'action' object to get the payload and other params similar to your old saga.
  const headers = {
    "X-Auth-Token": action.token,
    "content-type": "application/json"
  };
  const body = {
    "eventId": action.eventId,
    "qualified": action.qualified
  }

  const response = yield PUT(
    `${action.server}api/vume/qualification/event`,
    body,
    headers
  );

  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.qualifyEventSuccess(response));
  } else if (response && response.status === 403) {
    yield put(actions.userLogout(action.token, action.server));
    yield put(actions.qualifyEventFail(response));
  } else {
    yield put(actions.qualifyEventFail(response));
  }
}



export function* qualifyUserSaga(action) {
  yield put(actions.qualifyUserStart());

  const headers = {
    "X-Auth-Token": action.token,
    "content-type": "application/json"
  };

  const body = {
    "eventId": action.userId,
    "qualified": action.qualified
  }

  const response = yield PUT(
    `${action.server}api/vume/qualification/user`,
    body,
    headers
  );

  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.qualifyUserSuccess(response));
  } else if (response && response.status === 403) {
    yield put(actions.userLogout(action.token, action.server));
    yield put(actions.qualifyUserFail(response));
  } else {
    yield put(actions.qualifyUserFail(response));
  }
}




export function* preQualifyUserSaga(action) {
  yield put(actions.preQualifyUserStart());

  const headers = {
    "X-Auth-Token": action.token,
    "content-type": "application/json"
  };

  const body = {
    "email": action.email
  }

  const response = yield PUT(
    `${action.server}api/vume/qualification/user/new`,
    body,
    headers
  );

  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  } else if (response && response.status === 200) {
    yield put(actions.preQualifyUserSuccess(response));
  } else if (response && response.status === 403) {
    yield put(actions.userLogout(action.token, action.server));
    yield put(actions.preQualifyUserFail(response));
  } else {
    yield put(actions.preQualifyUserFail(response));
  }
}
