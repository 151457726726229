import {
  watchEvents,
  watchLocations,
  watchAuth,
  watchChangePassword,
  watchStreams,
  watchGetMetaData,
  watchPlans,
  watchTags,
  watchRoles,
  watchUsers,
  watchUserPlans,
  watchReports,
  watchCache,
  watchMonitoring,
  watchComments,
  watchQualification,
} from "@pmviulive/vume-js";
import { fork, all } from "redux-saga/effects";

export default function* rootSaga() {
  yield all([
    fork(watchEvents),
    fork(watchStreams),
    fork(watchLocations),
    fork(watchAuth),
    fork(watchChangePassword),
    fork(watchGetMetaData),
    fork(watchTags),
    fork(watchPlans),
    fork(watchRoles),
    fork(watchUsers),
    fork(watchUserPlans),
    fork(watchReports),
    fork(watchCache),
    fork(watchMonitoring),
    fork(watchComments),
    fork(watchQualification),
  ]);
}
