export const ROLE_MANAGEMENT = "ROLE_GENERAL_SERVICE_ROLE_MANAGEMENT";
export const LOCATION_MANGEMENT = "ROLE_GENERAL_SERVICE_LOCATION_MANAGEMENT";
export const EVENT_TYPE = "ROLE_VUME_SERVICE_EVENT_TYPE";
export const PLAN_MANGEMENT = "ROLE_GENERAL_SERVICE_PLAN_MANAGEMENT";
export const EVENT_REPORT = "ROLE_VUME_EVENT_REPORT_MANAGEMENT";
export const SERVICE_TEAM = "ROLE_VUME_SERVICE_TEAM";
export const EVENT_ENTERPRISE = "ROLE_VUME_SERVICE_EVENT_ENTERPRISE";
export const PLAYER_SIMPLE = "ROLE_VUME_SERVICE_PLAYER_SIMPLE";
export const PLAYER_ENTERPRISE = "ROLE_VUME_SERVICE_PLAYER_ENTERPRISE";
export const EVENT_SIMPLE = "ROLE_VUME_SERVICE_EVENT_SIMPLE";
export const USER_MANAGEMENT = "ROLE_GENERAL_SERVICE_USER_MANAGEMENT";
export const ADMIN_CONSOLE = "ROLE_GENERAL_ADMIN_CONSOLE";
export const NOTIFICATION_MANAGEMENT= "ROLE_GENERAL_SERVICE_USER_NOTIFICATION_MANAGEMENT"
export const CACHE_MANAGEMENT= "ROLE_GENERAL_SERVICE_CACHE_MANAGEMENT"
