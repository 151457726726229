import * as actionTypes from "./actionTypes";



export const qualifyEvent = function qualifyEvent(token, eventId, qualified, server) {
  return {
    type: actionTypes.QUALIFY_EVENT,
    eventId: eventId,
    qualified: qualified,
    server: server,
    token: token,
  };
};

export const qualifyEventStart = () => {
  return {
    type: actionTypes.QUALIFY_EVENT_START
  };
};

export const qualifyEventSuccess = response => {
  return {
    type: actionTypes.QUALIFY_EVENT_SUCCESS,
    response: response
  };
};

export const qualifyEventFail = error => {
  return {
    type: actionTypes.QUALIFY_EVENT_FAIL,
    error: error
  };
};



export const qualifyUser = function qualifyUser(token, userId, qualified, server) {
  return {
    type: actionTypes.QUALIFY_USER,
    userId: userId,
    qualified: qualified,
    server: server,
    token: token,
  };
};


export const qualifyUserStart = () => {
  return {
    type: actionTypes.QUALIFY_USER_START
  };
};

export const qualifyUserSuccess = response => {
  return {
    type: actionTypes.QUALIFY_USER_SUCCESS,
    response: response
  };
};

export const qualifyUserFail = error => {
  return {
    type: actionTypes.QUALIFY_USER_FAIL,
    error: error
  };
};



export const preQualifyUser = function preQualifyUser(token, email, server) {
  return {
    type: actionTypes.PREQUALIFY_USER,
    email: email,
    server: server,
    token: token,
  };
};
export const preQualifyUserStart = () => {
  return {
    type: actionTypes.PREQUALIFY_USER_START
  };
};

export const preQualifyUserSuccess = response => {
  return {
    type: actionTypes.PREQUALIFY_USER_SUCCESS,
    response: response
  };
};

export const preQualifyUserFail = error => {
  return {
    type: actionTypes.PREQUALIFY_USER_FAIL,
    error: error
  };
};


