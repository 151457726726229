import * as actionTypes from './actionTypes'

export const getPlans = (token, searchModel, server) => {
    return {
        type: actionTypes.GET_PLANS,
        token: token,
        searchModel: searchModel,
        server: server,
    };
};

export const getPlansStart = (loadMore) => {
    return {
        type: actionTypes.GET_PLANS_START,
    };
};

export const getPlansSuccess = plans => {
    return {
        type: actionTypes.GET_PLANS_SUCCESS,
        plans: plans,
    };
};

export const getPlansFail = error => {
    return {
        type: actionTypes.GET_PLANS_FAIL,
        error: error
    };
};
export const addPlan = (token, data, server) => {
    return {
        type: actionTypes.ADD_PLAN,
        data: data,
        server: server,
        token: token
    };
};

export const addPlanStart = () => {
    return {
        type: actionTypes.ADD_PLAN_FAIL,
    };
};

export const addPlanSuccess = response => {
    return {
        type: actionTypes.ADD_PLAN_SUCCESS,
        response: response,
    };
};

export const addPlanFail = error => {
    return {
        type: actionTypes.ADD_PLAN_FAIL,
        error: error
    };
};
// ADD Plan


export const deletePlan = (token, id, server) => {
    return {
        type: actionTypes.DELETE_PLAN,
        id: id,
        server: server,
        token: token
    };
};

export const deletePlanStart = () => {
    return {
        type: actionTypes.DELETE_PLAN_START,
    };
};

export const deletePlanSuccess = response => {
    return {
        type: actionTypes.DELETE_PLAN_SUCCESS,
        response: response,
    };
};

export const deletePlanFail = error => {
    return {
        type: actionTypes.DELETE_PLAN_FAIL,
        error: error
    };
};
  // ============================================= DELETE PLAN

  export const getPlanById = (token,id,server) => {
    return {
      type: actionTypes.GET_PLAN_BY_ID,
      id: id,
      server:server,
      token:token
    };
  };
  
  export const getPlanByIdStart = () => {
    return {
      type: actionTypes.GET_PLAN_BY_ID_START,
    };
  };
  
  export const getPlanByIdSuccess = Plan => {
    return {
      type: actionTypes.GET_PLAN_BY_ID_SUCCESS,
      Plan: Plan,
    };
  };
  
  export const getPlanByIdFail = error => {
    return {
      type: actionTypes.CHECK_EVENT_BY_ID_FAIL,
      error: error
    };
  };
  // ============================================= PLAN BY ID


  export const editPlan = (token,data,server) => {
    return {
      type: actionTypes.EDIT_PLAN,
      data: data,
      server:server,
      token:token
    };
  };
  
  export const editPlanStart = () => {
    return {
      type: actionTypes.EDIT_PLAN_START,
    };
  };
  
  export const editPlanSuccess = response => {
    return {
      type: actionTypes.EDIT_PLAN_SUCCESS,
      response: response,
    };
  };
  
  export const editPlanFail = error => {
    return {
      type: actionTypes.EDIT_PLAN_FAIL,
      error: error
    };
  };
  // ============================================= EDIT PLAN