import { put } from "redux-saga/effects";
import * as actions from "../actions/index";
import { GET } from "../callApiWrapper";

export function* getMonitoringSaga(action) {
  yield put(actions.getMonitoringStart());
  const headers = {
    "content-type": "application/json",
    "X-Auth-Token": action.token
  };

  const response = yield GET(`${action.server}api/monitoring`, headers);
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.getMonitoringSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getMonitoringFail(response));
  }
}

export function* getAppVersionStatusSaga(action) {
  yield put(actions.getAppVersionStatusStart());
  const headers = {
    "content-type": "application/json"
  };

  const response = yield GET(
    `${action.server}api/system/checkversion/${action.version}`,
    headers
  );
  if (response && response === 503) {
    yield put(actions.serverErrorSuccess(response));
  }
  if (response && response.status === 200) {
    yield put(actions.getAppVersionStatusSuccess(response.data));
  } else if (response && (response.status === 403 || response.status === 401)) {
    yield put(actions.userLogout(action.token, action.server));
  } else {
    yield put(actions.getAppVersionStatusFail(response));
  }
}
