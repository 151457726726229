import * as actionTypes from './actionTypes'

export const clearCache = (token,server) => {
  return {
    type: actionTypes.CLEAR_CACHE,
    server:server,
    token:token
  };
};

export const clearCacheStart = () => {
    return {
      type: actionTypes.CLEAR_CACHE_START,
    };
  };
  
  export const clearCacheSuccess = response => {
    return {
      type: actionTypes.CLEAR_CACHE_SUCCESS,
      response: response,
    };
  };
  
  export const clearCacheFail = error => {
    return {
      type: actionTypes.CLEAR_CACHE_FAIL,
      error: error
    };
  };